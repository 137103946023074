<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_3">
      리뷰놀이터
      <div class="search">
        <div class="input_box">
          <input
            type="text"
            name=""
            v-model="searchText"
            placeholder="상품 리뷰를 검색하세요."
            @keydown.enter="goSearch"
          />
        </div>
        <router-link to="" @click.native="goSearch"
          ><img src="@/assets/images/sub/alarm_search_bt.png" alt=""
        /></router-link>
      </div>
    </div>
    <!---->
    <!-- <div class="rn_my_empty_30"></div> -->
    <!---->
    <MainMenu
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      :isGoods="true"
      :isSeller="true"
      :allowMenu="allowMenu"
    />
    <!---->
    <div class="rn_ms_section" v-if="showPage">
      <!---->
      <div class="cate_wrap">
        <div class="cate_left">
          <router-link to="" @click.native="goMain"
            ><img src="@/assets/images/sub/cate_menu_ico.png" alt=""
          /></router-link>
        </div>
        <div class="cate_right">
          <!-- -->
          <GoodsCategory
            :category="categories"
            :id="id"
            :open="open"
            ref="goodsCategory"
            @getGoodsCategory="getGoodsCategory"
          />
          <!-- -->
        </div>
      </div>
      <!---->
      <div class="cate_view">
        <div class="step_1">
          <div
            class="step_1_box"
            v-for="(cate, index) in copyCategories"
            :key="cate.id"
          >
            <span
              class="step_1_span"
              data-level="3"
              :data-id="cate.id"
              :data-index="index"
              @click="moreCategory(index, $event)"
              >{{ cate.title }}</span
            ><router-link to="" class="step_1_a"
              ><img src="@/assets/images/sub/my_down_w_ico.png" alt=""
            /></router-link>
            <template v-for="level2 in lowCategorise2">
              <div
                v-if="parseInt(level2.parent_id) === parseInt(cate.id)"
                :id="`${id}_${level2.parent_id}_${cate.id}`"
                :class="`step_2_box`"
                :key="`${level2.id}_2`"
              >
                <span
                  class="step_2_span"
                  data-level="4"
                  :data-id="level2.id"
                  @click="moreCategory(level2.id, $event)"
                  >{{ level2.title }}</span
                ><router-link to="" class="step_2_a"
                  ><img src="@/assets/images/sub/my_down_w_ico.png" alt=""
                /></router-link>
                <div
                  class="step_3"
                  v-for="level3 in lowCategorise3"
                  :key="`${level3.id}_3`"
                >
                  <div
                    v-if="parseInt(level3.parent_id) === parseInt(level2.id)"
                    :id="`${id}_${level3.parent_id}_${level2.id}`"
                    class="step_3_box"
                  >
                    <span
                      class="step_3_span"
                      data-level="5"
                      :data-id="level3.id"
                      @click="moreCategory(level3.id, $event)"
                      >{{ level3.title }}</span
                    ><router-link to="" class="step_3_a"
                      ><img src="@/assets/images/sub/my_down_ico.png" alt=""
                    /></router-link>
                    <template v-for="level4 in lowCategorise4">
                      <div
                        class="step_4_box"
                        v-if="
                          parseInt(level4.parent_id) === parseInt(level3.id)
                        "
                        :id="`${id}_${level4.parent_id}_${level3.id}`"
                        :key="`${level4.id}_4`"
                        @click="goReivewGoodsList(level4.id)"
                      >
                        <router-link to="">{{ level4.title }}</router-link>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!---->
    </div>
    <div class="ready_menu" v-else>
      <p>서비스 준비중 입니다.</p>
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainMenu from "@/components/shop/menu/MainMenu";
import GoodsCategory from "@/components/shop/menu/GoodsCategory";
export default {
  data() {
    return {
      showPage: true,
      first: true,
      id: null,
      cate1: this.$route.query.cate1 || null,
      cate2: this.$route.query.cate2 || null,
      cate3: this.$route.query.cate3 || null,
      categoryCode: this.$route.query.category_code || null,
      mainCate: null,
      fisrtId: null,
      level: null,
      open: this.$route.params.open || false,
      allowMenu: [1, 3], //현재 램 장터메 활성화된 메뉴 번호
      searchText: "",
    };
  },
  created() {
    //초기화
    this.$store.dispatch("shop/dataClear");
    this.getMainMenu();
  },
  components: {
    MainMenu,
    GoodsCategory,
  },
  computed: {
    ...mapState("review", ["mainMenuList", "categoryName"]),
    ...mapState("shop", [
      "result",
      "msg",
      "totalPage",
      "categories",
      "copyCategories",
      "lowCategorise2",
      "lowCategorise3",
      "lowCategorise4",
    ]),
  },
  methods: {
    goSearch() {
      if (this.searchText.replace(/\s/g, "").length <= 0) {
        this.$toast.default("검색어를 입력하세요.");
        return false;
      }
      this.$router.push(`/search/review_search/1?search=${this.searchText}`);
    },
    isUseMenu(menu, number = null) {
      // console.log("isUseMenu", menu, number, this.category_code);
      const menuInfo = this.mainMenuList.filter(
        (main) => parseInt(main.seq) === parseInt(menu)
      );
      // console.log(menuInfo);
      const menuIndex = this.mainMenuList.findIndex(
        (main) => parseInt(main.seq) === parseInt(menu)
      );
      return [
        menuInfo[0].use_yn === "Y" ? true : false,
        menuIndex,
        menuInfo[0],
      ];
    },
    goReivewGoodsList(id) {
      const cate = this.lowCategorise4.filter(
        (item) => parseInt(item.id) === parseInt(id)
      );
      let url = null;
      url = `/review/goods_main/${this.fisrtId}/${cate[0].category_code}`;
      this.$router.push(url);
      // console.log(this.mainCate);
      // if (parseInt(this.mainCate) === 1) {
      //   url = `/review/goods_main/${this.fisrtId}/${cate[0].category_code}`;
      // }
      // if (url) {
      //   this.$router.push(url);
      // }
    },
    getGoodsCategory(id) {
      this.id = id;
      // const el = document.querySelectorAll(".review.swiper-slide");
      // let target = null;
      // [...el].map((e) => {
      //   if (e.dataset.id === id) {
      //     target = e;
      //   }
      // });

      let cpCateIdx = this.copyCategories.findIndex(
        (x) => parseInt(x.id) === parseInt(this.id)
      );

      console.log("cate :", this.open, this.first);
      if (!this.first && !this.open) {
        // document
        //   .querySelectorAll(".step_1_span")
        //   [target.dataset.swiperSlideIndex].click();
        document.querySelectorAll(".step_1_span")[cpCateIdx].click();
      }
      // console.log("getCategory");
      this.first = false;
    },
    async getCategories() {
      await this.$store.dispatch("shop/getCategories");
      this.$refs.goodsCategory.reflash();
      if (this.open === "open" && this.cate1 !== null) {
        this.openCategory();
      }
    },
    async moreCategory(index, event, open = false, obj = null) {
      if (!open) {
        this.id = event.target.dataset.id; //id 세팅
        this.level = parseInt(event.target.dataset.level); // 레벨 세팅
      } else {
        this.id = obj.dataset.id;
        this.level = parseInt(obj.dataset.level);
        // console.log("this.level", this.level);
      }
      //하위 카테고리 1의 id 세팅
      if (parseInt(this.level) === 3) {
        this.fisrtId = this.id;
      }
      const modify = 2; //레벨 조정 변수

      if (!open) {
        //레벨이 1 카테고리라면 상단에 카테고리 메뉴 이동
        if (parseInt(this.level - modify) <= 1) {
          let orgIdx = this.categories.findIndex(
            (x) => parseInt(x.id) === parseInt(this.copyCategories[index].id)
          );
          this.$refs.goodsCategory.goSlide(orgIdx);
        }
      }

      let target = null;
      if (!open) {
        //클릭된 대상
        target = event.target.closest("div");
      } else {
        target = obj.closest("div");
      }

      //현재 클릭된 레벨의 카테고리 초기화
      this.$store.dispatch("shop/clearLowCategory", parseInt(this.level));

      //닫기 아이콘 초기화
      const stepBox = document.querySelectorAll(`.${target.classList[0]}`);
      [...stepBox].map((step) => {
        step.querySelector("img").style.transform = "rotate(0deg)";
      });

      //대상이 open 클래스를 가지있지 않으면 open 부여후 데이터 가져오고 바로 아래 레벨 카테고리 엘레먼트 block처리
      if (!target.classList.contains("open")) {
        target.classList.add("open");
        target.querySelector("img").style.transform = "rotate(180deg)";
        await this.$store.dispatch("shop/getGoodsLowCategory", {
          level: this.level,
          id: this.id,
          is_sort: event ? !event.isTrusted : true,
        });

        if (!this.result) {
          this.$toast.default(this.msg);
          target.querySelector("img").style.transform = "rotate(0deg)";
        }
      } else {
        target.classList.remove("open");
        target.querySelector("img").style.transform = "rotate(0deg)";

        //타겟 보다 하나 아래 카테고리 최기화
        // this.$store.dispatch("shop/clearLowCategory", parseInt(this.level) + 1);
      }
      [...document.querySelectorAll(`.${target.classList[0]}`)].map((step) => {
        if (step !== target) step.classList.remove("open");
      });

      if (parseInt(this.level) >= 5) {
        this.open = false;
      }
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
      this.getCategories();
    },

    async openCategory() {
      const firstCate = document.querySelector(
        `.step_1_span[data-id="${this.cate1}"]`
      );

      await this.moreCategory(
        parseInt(firstCate.dataset.index),
        null,
        true,
        firstCate
      );

      let orgIdx = this.categories.findIndex(
        (x) => parseInt(x.id) === parseInt(firstCate.dataset.id)
      );

      this.$refs.goodsCategory.goSlide(parseInt(orgIdx));
      const cate2 = document.querySelector(
        `.step_2_span[data-id="${this.cate2}"]`
      );
      await this.moreCategory(parseInt(cate2.dataset.id), null, true, cate2);
      const cate3 = document.querySelector(
        `.step_3_span[data-id="${this.cate3}"]`
      );
      await this.moreCategory(parseInt(cate3.dataset.id), null, true, cate3);

      this.open = false;
      this.getGoodsCategory(firstCate.dataset.id);
    },
    goMain() {
      let url = "/review/goods_main";
      if (this.open === "open" && this.categoryName.length > 0) {
        url = `${url}/${this.category_code}?goods=0&option1=1&option2=3&option3=0&page=1&seq=0`;
      }
      this.$router.push(url);
    },
    getMenu(seq) {
      this.mainCate = seq;

      if (parseInt(this.mainCate) === 1) {
        this.$router.push("/review/goods_main");
        return false;
      }
      if (parseInt(this.mainCate) === 3) {
        this.$router.push("/review/news_main");
        return false;
      }

      if (!this.first || !this.open) {
        const [useYn, menuIndex] = this.isUseMenu(this.mainCate);
        if (useYn) {
          this.showPage = true;
        } else {
          this.showPage = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ready_menu {
  text-align: center;
  font-weight: 600;
  padding: 40px 0px 0px 0px;
  font-size: 14px;
}
</style>

import { render, staticRenderFns } from "./ReviewGoodsCategoryList.vue?vue&type=template&id=0ecfae0f&scoped=true&"
import script from "./ReviewGoodsCategoryList.vue?vue&type=script&lang=js&"
export * from "./ReviewGoodsCategoryList.vue?vue&type=script&lang=js&"
import style0 from "./ReviewGoodsCategoryList.vue?vue&type=style&index=0&id=0ecfae0f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ecfae0f",
  null
  
)

export default component.exports